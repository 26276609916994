import {mapActions, mapGetters} from "vuex";

import feedbackForm from './components/feedback-form/index.vue'
import contactMap from './components/map/index.vue'
import contactRss from './components/contact-rss/index.vue'

export default {
  name: 'contacts',
  data() {
    return {
      params: {},
      phone: null,
      email: null,
      address: null,
      schedule: null,
      viber: null,
      instagram: null,
      facebook: null,
      contactsDescription:null
    }
  },
  components: {
    feedbackForm,
    contactMap,
    contactRss
  },
  head: {
    title: function () {
      return {
        inner: 'DeLav ',
        separator: '|',
        complement: 'Контакты'
      }
    },
  },
  created() {
    this.fetchVariables().then(() => {
      this.phone = this.variables.find(e => e.key === 'phone')
      this.email = this.variables.find(e => e.key === 'email')
      this.address = this.variables.find(e => e.key === 'address')
      this.viber = this.variables.find(e => e.key === 'viber')
      this.schedule = this.variables.find(e => e.key === 'schedule')
      this.instagram = this.variables.find(el => el.key === 'instagram')
      this.facebook = this.variables.find(el => el.key === 'facebook')
      this.contactsDescription = this.variables.find(el => el.key === 'contacts_description')
    })
  },
  computed: {
    ...mapGetters({
      variables: 'setting/variables'
    }),
  },
  methods: {
    ...mapActions({
      fetchVariables: 'setting/GET_VARIABLES'
    })
  }
}
