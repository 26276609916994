import {mapMutations, mapGetters, mapActions} from "vuex";

export default {
  name: 'contact-map',
  data() {
    return {
      payload: {
      },
    }
  },
  components: {},
  computed: {
    ...mapGetters({
    }),
  },
  created() {
  },
  methods: {
    ...mapActions({
    }),
    ...mapMutations({}),
  },
  destroyed() {

  }
}
