import {mapMutations, mapGetters, mapActions} from "vuex";
import {email, maxLength, minLength, required} from "vuelidate/lib/validators";
import {validationMixin} from "vuelidate";

export default {
  name: 'feedback-form',
  mixin: [validationMixin],
  data() {
    return {
      payload: {
        name: '',
        email: '',
        message: '',
        phone: '',
        file: []
      },
      previewImg: '',
      validationErrors: {},
    }
  },
  validations: {
    payload: {
      name: {
        required,
        minLength: minLength(3),
        maxLength: maxLength(20),
      },
      email: {
        required,
        maxLength: maxLength(50),
        email
      },
      message: {
        required,
        minLength: minLength(10),
        maxLength: maxLength(1000),
      },
      phone: {
        required,
        minLength: minLength(19)
      },
    }
  },
  components: {},
  computed: {
    ...mapGetters({
      loading: 'contacts/loadingFeedback',
    }),
    emailErrors() {
      let error = [];
      if (!this.$v.payload.email.$dirty) {
        return error;
      }
      if (!this.$v.payload.email.maxLength) {
        error.push(this.$t('validationMax').replace(':count', 50));
      }
      if (!this.$v.payload.email.required) {
        error.push(this.$t('validationRequired'))
      }
      if (!this.$v.payload.email.email) {
        error.push(this.$t('validationEmail'))
      }
      if (this.validationErrors.email) {
        this.validationErrors.email.forEach((row) => {
          error.push(row);
        });
      }
      return error;
    },
    nameErrors() {
      let error = [];
      if (!this.$v.payload.name.$dirty) {
        return error;
      }
      if (!this.$v.payload.name.required) {
        error.push(this.$t('validationRequired'))
      }
      if (!this.$v.payload.name.minLength) {
        error.push(this.$t('validationMin').replace(':count', 3));
      }
      if (!this.$v.payload.name.maxLength) {
        error.push(this.$t('validationMax').replace(':count', 20));
      }
      if (this.validationErrors.name) {
        this.validationErrors.name.forEach((row) => {
          error.push(row);
        })
      }
      return error;
    },
    messageErrors() {
      let error = [];
      if (!this.$v.payload.message.$dirty) {
        return error;
      }
      if (!this.$v.payload.message.required) {
        error.push(this.$t('validationRequired'))
      }
      if (!this.$v.payload.message.minLength) {
        error.push(this.$t('validationMin').replace(':count', 10));
      }
      if (!this.$v.payload.message.maxLength) {
        error.push(this.$t('validationMax').replace(':count', 100));
      }
      if (this.validationErrors.message) {
        this.validationErrors.message.forEach((row) => {
          error.push(row);
        })
      }
      return error;
    },
    phoneErrors() {
      let error = [];
      if (!this.$v.payload.phone.$dirty) {
        return error;
      }
      if (!this.$v.payload.phone.required) {
        error.push(this.$t('validationRequired').replace(':name', this.$t('phone')))
      }
      if (!this.$v.payload.phone.minLength) {
        error.push(this.$t('validationLengthPhone'));
      }
      if (this.validationErrors.phone) {
        this.validationErrors.phone.forEach((row) => {
          error.push(row);
        })
      }
      return error;
    },
    disableButton() {
      if (
        this.payload.name === '' ||
        this.payload.email === '' ||
        this.payload.message === ''
      ) {
        return true
      }
      return false
    },
  },
  created() {
  },
  methods: {
    ...mapActions({
      feedback: `contacts/SEND_FEEDBACK`,
    }),
    ...mapMutations({}),
    changeFeedbackStatus(status) {
      this.payload.status = status;
    },
    resetForm() {
      this.$v.$reset();
      this.payload.email = '';
      this.payload.name = '';
      this.payload.message = '';
      this.payload.file = [];
      this.payload.phone = '';
      this.previewImg = ''
    },
    parsFormData(obj) {
      let fd = new FormData();
      for (let i in obj) {
        if (i === 'file') {
          for (let j in obj[i]) {
            fd.append(`file[${j}]`, obj[i][j]);
          }
        } else {
          fd.append(i, obj[i])
        }
      }
      for (let value of fd.values()) {

      }

      return fd
    },
    submit() {
      this.$v.$touch();
      if (!this.$v.$invalid) {
        // let obj = {
        //   name: this.payload.name,
        //   email: this.payload.email,
        //   message: this.payload.message,
        //   phone: this.payload.phone,
        // };
        this.feedback(this.parsFormData(this.payload)).then(() => {
          this.resetForm();
          this.$toasted.success(this.$t('successAddFeedback'));
        }).catch(error => {
          if (error.response.status === 422) {
            this.validationErrors = error.response.data.errors;
          }
        });
      }
    },
    handleFileChange(evt) {
      let arr = Array.from(evt.target.files)
      this.payload.file = this.payload.file.concat(arr)

      this.previewImg = URL.createObjectURL(evt.target.files[0]);
    },
    removeFile() {

      this.payload.file = []
      this.previewImg = ''
    },
  },
  destroyed() {

  }
}
