import {mapMutations, mapGetters, mapActions} from "vuex";
import {email, maxLength, minLength, required} from "vuelidate/lib/validators";
import {validationMixin} from "vuelidate";

export default {
  name: 'contact-rss',
  mixin: [validationMixin],
  data() {
    return {
      payload: {
        email: ''
      },
      validationErrors: {},
    }
  },
  validations: {
    payload: {
      email: {
        required,
        maxLength: maxLength(50),
        email
      },
    }
  },
  components: {},
  computed: {
    ...mapGetters({
    }),
    emailErrors() {
      let error = [];
      if (!this.$v.payload.email.$dirty) {
        return error;
      }
      if (!this.$v.payload.email.maxLength) {
        error.push(this.$t('validationMax').replace(':count', 50));
      }
      if (!this.$v.payload.email.required) {
        error.push(this.$t('validationRequired'))
      }
      if (!this.$v.payload.email.email) {
        error.push(this.$t('validationEmail'))
      }
      if (this.validationErrors.email) {
        this.validationErrors.email.forEach((row) => {
          error.push(row);
        });
      }
      return error;
    },
    disableButton(){
      if(
        this.payload.email === ''
      ){
        return true
      }
      return false
    },
  },
  created() {
  },
  methods: {
    ...mapActions({
      sendSubscribe:'contacts/SUBSCRIBE'
    }),
    ...mapMutations({}),
    resetForm() {
      this.$v.$reset();
      this.payload.email = '';
    },
    submit() {
      this.$v.$touch();
      if (!this.$v.$invalid) {
        let obj = {
          email: this.payload.email,
        };
        this.sendSubscribe(obj).then(() => {
          this.resetForm();
          this.$toasted.success(this.$t('successAddFeedback'));
        }).catch(error => {
          if (error.response.status === 422) {
            this.validationErrors = error.response.data.errors;
          }
        });
      }
    },
  },
  destroyed() {

  }
}
